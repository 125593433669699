import React, {useState} from 'react';
import {Button, Chip, IconButton, Stack, Typography} from "@mui/material";
import {IPublicGroupInfo} from "./model/IPublicGroupInfo";
import {GroupStatus} from "./model/IGroupStatus";
import CloseIcon from '@mui/icons-material/Close';
import {referralAtom} from "../../../store/referralStore";
import {useAtomValue} from 'jotai';

export const KOTHINKER_URL = process.env.REACT_APP_KOTHINKER_WEB_URL;

function GroupSlide({groupInfo, isWaitList = false}: { groupInfo: IPublicGroupInfo, isWaitList?: boolean }) {
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false)
    const referralCode = useAtomValue(referralAtom);

    function getLanguageText() {
        if (groupInfo.languageCode === "en") {
            return <>In English</>
        } else if (groupInfo.languageCode === "et") {
            return <>In Estonian</>
        }
        return <></>;
    }

    function getStatusChip() {
        if (groupInfo.status === GroupStatus.PENDING) {
            return <Chip label="In progress"
                         variant="outlined"
                         size="small"
                         sx={{
                             backgroundColor: 'transparent',
                             color: '#FFE500',
                             borderColor: '#FFF855',
                             padding: '0px 8px',
                             fontSize: '0.75rem',
                         }}
            />;
        } else if (groupInfo.status === GroupStatus.ACTIVE) {
            return <Chip label="Full"
                         variant="outlined"
                         size="small"
                         sx={{
                             backgroundColor: 'transparent',
                             opacity: 0.35,
                             color: 'white',
                             borderColor: 'white',
                             padding: '0px 8px',
                             fontSize: '0.75rem',
                         }}
            />;
        }
    }


    return (
        // <Grid container spacing={2} style={{height: "100px", width: "100px"}}>
        //     <Grid item>test</Grid>
        //     <Grid item>test</Grid>
        // </Grid>
        <Stack
            className="group-selection-box"
            sx={{
                position: "relative", backgroundColor: "orange",
                borderRadius: {md: "20px", xs: "2.5rem"},
                padding: {md: 0, xs: "1rem"},
                height: {md: "100%", xs: "calc(100% - 4rem)"},
                width: "17rem",
                minHeight: "27rem",
            }}>


            <Stack sx={{height: "100%"}}>
                {!showMoreInfo ?
                    <Stack direction="column"
                           sx={{
                               alignItems: "start",
                               position: "relative",
                               minHeight: "23rem",
                               height: "100%",
                               padding: "24px",
                               paddingBottom: "0px",
                               justifyContent: 'space-between',
                           }}>
                        <Stack width="100%">
                            <Stack direction="row" sx={{
                                justifyContent: 'space-between',
                                width: '100%', // Set the width to 100% if you want the Stack to take up the full width of its container
                            }}>
                                <Typography sx={{
                                    color: "#9386C2",
                                    fontWeight: 400,
                                    fontSize: {
                                        xs: "0.875rem !important",
                                        lg: "1rem !important",
                                        md: "0.875rem !important"
                                    },
                                }}>{!isWaitList && getLanguageText()}</Typography>
                                {getStatusChip()}
                            </Stack>
                            <Stack sx={{marginTop: "0.5rem"}}>
                                {!isWaitList && <Typography sx={{
                                    fontSize: "1rem",
                                    lineHeight: "1.125rem",
                                    color: "white",
                                    paddingRight: "1rem"
                                }}>{groupInfo.sessionTime && groupInfo.sessionTime != "" ? groupInfo.sessionTime : "Frequency: TBA"}</Typography>}

                                <Typography sx={{
                                    fontSize: {xs: "1.5rem", md: "1.75rem"},
                                    color: "white",
                                    fontWeight: 700,
                                    marginTop: isWaitList ? "2.125rem" : "1rem",
                                    lineHeight: {xs: "1.6rem", md: "2rem"},
                                }}>{groupInfo.name}</Typography>
                                {!isWaitList && groupInfo.maxSize && groupInfo.status === GroupStatus.PENDING && <Typography sx={{
                                    fontSize: "1rem",
                                    fontWeight: 400,
                                    lineHeight: "1.125rem",
                                    color: "white",
                                }}>{groupInfo.memberCount} out of {groupInfo.maxSize} filled
                                </Typography>}
                                {isWaitList && <Typography sx={{
                                    mt: "1rem",
                                    fontSize: "1rem",
                                    fontWeight: 400,
                                    lineHeight: "1.125rem",
                                    color: "white",
                                }}>{groupInfo.subName}
                                </Typography>}
                                {groupInfo.companies && groupInfo.companies.length > 0 && <Typography sx={{
                                    fontSize: "1rem",
                                    lineHeight: "1.125rem",
                                    color: "#9386C2",
                                }}>Members from {groupInfo.companies.join(", ")}.
                                </Typography>}
                            </Stack>
                        </Stack>
                        <Stack width="100%">
                            <Button
                                disabled={groupInfo.status !== GroupStatus.PENDING}
                                variant="contained"
                                onClick={(e) => {
                                    window.location.href = referralCode ? `${KOTHINKER_URL}?group=${groupInfo.id}&ref=` + referralCode : `${KOTHINKER_URL}?group=${groupInfo.id}`;
                                    e.stopPropagation();
                                    return false;
                                }}
                                fullWidth // Makes the button full width
                                sx={{
                                    backgroundColor: groupInfo.status === GroupStatus.PENDING ? '#0CDBA6' : '#6B469B !important',
                                    color: groupInfo.status === GroupStatus.PENDING ? '#FFFFFF' : '#865FB9 !important',
                                    border: 'none',
                                    marginTop: '1rem',
                                    borderRadius: '6px',
                                    '&:hover': {
                                        backgroundColor: '#04b084', // Darker green on hover
                                    },
                                }}
                            >
                                Apply
                            </Button>
                            <Typography
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowMoreInfo(true);

                                    return false;
                                }}
                                align="center"
                                paddingTop="1.5rem"
                                sx={{
                                    zIndex: 100,
                                    padding: "1.5rem",
                                    cursor: "pointer",
                                    fontSize: "1rem",
                                    fontWeight: 400,
                                    lineHeight: "1.125rem",
                                    color: "white",
                                }}
                            >More information
                            </Typography>
                        </Stack>
                    </Stack>
                    :
                    <Stack
                        direction="column"
                        sx={{
                            alignItems: "start",
                            position: "relative",
                            height: "100%",
                            padding: "24px",
                            paddingTop: "14px",
                        }}
                    >
                        <Stack direction="row" justifyContent="end" width="100%">
                            <IconButton edge="end" color="primary" aria-label="Close" onClick={(e) => {
                                setShowMoreInfo(false);
                                e.stopPropagation();
                            }}>
                                <CloseIcon/>
                            </IconButton>
                        </Stack>
                        <Typography
                            onClick={(e) => {
                                setShowMoreInfo(false);
                                e.stopPropagation();
                            }}
                            align="left"
                            sx={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                lineHeight: "1.125rem",
                                color: "white",
                                whiteSpace: "pre-line",
                            }}
                        >
                            {groupInfo.moreInfo && groupInfo.moreInfo != "" ? groupInfo.moreInfo : "Currently no additional information available. Please check back later."}

                        </Typography>
                    </Stack>

                }
            </Stack>
        </Stack>
    )
        ;
}

export default GroupSlide;
